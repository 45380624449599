
import { defineComponent, onMounted, PropType, ref, watch } from "vue";
import { formatCurrencyAndQuantity, strip, swalNotification } from "@/core/helpers/utils";
import {
  IVatTuXuatKhoResponseBase,
  IKhoResponseBase,
  IVatTuTrongKhoResponseBase,
} from "@/core/interfaces/ApiResponses";
import { IVatTuSelectedInKho } from "@/core/interfaces/Models";
import { useButtonCustom } from '../../../../../core/hooks/use-button-custom';
import { intersectionWith } from 'lodash';
import { LOAI_DINH_MUC } from '../../../../../core/constants/const';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import ButtonCustom from '../../../../../components/shared/buttons/ButtonCustom.vue';
import useVatTu from "@/core/hooks/use-vat-tu";

export default defineComponent({
  name: 'supplies-table',

  components: { ButtonCustom },

  emits: [
    'on-detail-button-click',
    'on-next-button-click',
    'on-click-clear-quantity-data',
    'on-confirm-button-click',
  ],

  data() {
    return {
      formatCurrencyAndQuantity,
    };
  },

  props: {
    isGettingAllSupplies: {
      type: Boolean,
      default: false,
    },
    loaiDinhMuc: {
      type: String as PropType<LOAI_DINH_MUC>,
			required: true,
    },
    selectingKhoItem: {
      type: Object as PropType<IKhoResponseBase | null>,
      default: null,
    },
    supplies: {
      type: Array as PropType<Array<IVatTuXuatKhoResponseBase>>,
      required: true,
    },
    vatTuSelectedInKho: {
      type: Array as PropType<Array<IVatTuSelectedInKho>>,
      required: true,
    },
  },

  setup(props) {
    const { ButtonsType, ButtonTypeColors } = useButtonCustom();

    const { isTextDangerColumn, isValidLabel } = useVatTu();

    const quantityData = ref<
      {
        id_vat_tu: number;
        so_luong_trong_kho_ban_dau: number;
        so_luong_se_lay: number;
        don_gia_trong_kho: number;
      }[]
    >([]);

    onMounted(() => {
      quantityData.value = [];
      if (props.supplies && props.supplies?.length > 0) {
        props.supplies?.forEach(({ id }) => {
          quantityData.value.push({
            id_vat_tu: id,
            so_luong_trong_kho_ban_dau: 0,
            so_luong_se_lay: 0,
            don_gia_trong_kho: 0,
          });
        });
      }
    });

    watch(
      () => props.selectingKhoItem,
      (warehouse) => {
        quantityData.value = [];

        if (!warehouse) return;

        const vat_tu_trong_kho = [...warehouse.vat_tu].filter(
          ({ pivot: { so_luong } }) => so_luong > 0
        );

        props.vatTuSelectedInKho?.forEach((vat_tu_dang_can) => {
          for (let i = 0; i < vat_tu_trong_kho.length; i++) {
            if (vat_tu_trong_kho[i].id === vat_tu_dang_can.id_vat_tu) {
              if (vat_tu_trong_kho[i].pivot.so_luong === 0) {
                // không tồn tại vật tư này trong kho:
                quantityData.value.push({
                  id_vat_tu: vat_tu_dang_can.id_vat_tu,
                  so_luong_trong_kho_ban_dau: 0,
                  so_luong_se_lay: vat_tu_dang_can.con_thieu,
                  don_gia_trong_kho: vat_tu_trong_kho[i].pivot.don_gia,
                });

                break;
              }

              if (
                vat_tu_trong_kho[i].pivot.so_luong <= vat_tu_dang_can.con_thieu
              ) {
                quantityData.value.push({
                  id_vat_tu: vat_tu_trong_kho[i].id,
                  so_luong_trong_kho_ban_dau: vat_tu_trong_kho[i].pivot.so_luong,
                  so_luong_se_lay: vat_tu_trong_kho[i].pivot.so_luong,
                  don_gia_trong_kho: vat_tu_trong_kho[i].pivot.don_gia,
                });
              } else if (
                vat_tu_trong_kho[i].pivot.so_luong > vat_tu_dang_can.con_thieu
              ) {
                quantityData.value.push({
                  id_vat_tu: vat_tu_trong_kho[i].id,
                  so_luong_trong_kho_ban_dau:
                    vat_tu_trong_kho[i].pivot.so_luong,
                  so_luong_se_lay: vat_tu_dang_can.con_thieu,
                  don_gia_trong_kho: vat_tu_trong_kho[i].pivot.don_gia,
                });
              }

              break;
            }
          }
        });
      },
      { deep: true }
    );

    return {
      quantityData,
      ButtonsType, ButtonTypeColors,
      isTextDangerColumn, isValidLabel,
    };
  },

  computed: {
    totalPrice() {
      if (this.selectingKhoItem && this.selectingKhoItem?.id < 0) {
        return 0;
      } else {
        let totalPrice = 0;

        this.quantityData.forEach((item, index) => {
          totalPrice += item.so_luong_se_lay * item.don_gia_trong_kho;
        });

        return formatCurrencyAndQuantity(Math.round(totalPrice), false);
      }
    },

    vatTuItemsComputed() {
      // Chưa chọn kho, hiển thị hết vật tư
      if (!this.selectingKhoItem) {
        return this.supplies;
      }

      // Trong kho đang chọn không có vật tư nào đáp ứng
      if (!this.selectingKhoItem.vat_tu || this.selectingKhoItem.vat_tu.length === 0) {
        return [];
      }

      // Hiển thị các vật tư có trong kho
      let result = intersectionWith(
        this.supplies,
        this.selectingKhoItem.vat_tu,
        (vatTu: IVatTuXuatKhoResponseBase, vatTuKho: IVatTuTrongKhoResponseBase) => vatTu.id === vatTuKho.id && vatTuKho.pivot.so_luong > 0,
      );

      result = result.map((vatTuBanDauItem) => {
        const vatTuTrongKhoItem = this.selectingKhoItem.vat_tu.find(
          ({ id: idVatTuKho }) => idVatTuKho === vatTuBanDauItem.id,
        );

        return {
          ...vatTuBanDauItem,
          ...(!!vatTuTrongKhoItem && {
            don_gia: vatTuTrongKhoItem.pivot?.don_gia,
          })
        }
      })

      return result;
    }
  },

  methods: {
    detailButtonClickedHandler(id: number, index: number) {
      this.$emit("on-detail-button-click", id, index);
    },

    async onQuantityGettingChangeHandler(index, value) {
      const foundIndex = this.indexConvert(index);

      if (value < 0) {
        swalNotification('Số lượng vật tư lấy phải dương', 'error');

        this.quantityData[index].so_luong_se_lay = 0;
      }

      if (
        this.loaiDinhMuc === LOAI_DINH_MUC.DINH_MUC_CO_DINH &&
        this.vatTuSelectedInKho[foundIndex].con_thieu -
          this.quantityData[index].so_luong_se_lay < 0 &&
        this.quantityData[index].so_luong_se_lay < this.quantityData[index].so_luong_trong_kho_ban_dau
      ) {
        const { isConfirmed } = await Swal.fire({
          title: 'Chú ý',
          text: 'Số lượng vật tư vượt quá số lượng trong định mức, bạn có chắc chắn muốn tiếp tục?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Huỷ',
        });

        if (!isConfirmed) {
          this.quantityData[index].so_luong_se_lay = this.vatTuSelectedInKho[foundIndex].con_thieu;
        }
      }

      if (
        this.vatTuSelectedInKho[foundIndex].con_thieu -
          this.quantityData[index].so_luong_se_lay <
        0 &&
        this.loaiDinhMuc !== LOAI_DINH_MUC.DINH_MUC_CO_DINH
      ) {
        await swalNotification('Không thể lấy quá số lượng cần thiết', 'error');

        this.quantityData[index].so_luong_se_lay =
          this.quantityData[index].so_luong_trong_kho_ban_dau >
          this.vatTuSelectedInKho[foundIndex].con_thieu
            ? this.vatTuSelectedInKho[foundIndex].con_thieu
            : this.quantityData[index].so_luong_trong_kho_ban_dau;
      }

      if (
        this.quantityData[index].so_luong_se_lay >
        this.quantityData[index].so_luong_trong_kho_ban_dau
      ) {
        await swalNotification('Không thể lấy nhiều hơn số lượng vật tư có sẵn trong kho', 'error');
        this.quantityData[index].so_luong_se_lay =
          this.quantityData[index].so_luong_trong_kho_ban_dau >
          this.vatTuSelectedInKho[foundIndex].con_thieu
            ? this.vatTuSelectedInKho[foundIndex].con_thieu
            : this.quantityData[index].so_luong_trong_kho_ban_dau;
      }
    },

    nextButtonClickedHandler() {
      if (this.isGettingAllSupplies) {
        this.$emit("on-confirm-button-click", this.quantityData);
      }
      this.$emit("on-next-button-click", this.quantityData);
    },

    clearQuantityDataClickedHandler() {
      this.$emit("on-click-clear-quantity-data");
    },

    calcMaximumQuantity(index: number) {
      const foundIndex = this.indexConvert(index);

      if (
        this.vatTuSelectedInKho[foundIndex].con_thieu <=
        this.quantityData[index].so_luong_trong_kho_ban_dau
      ) {
        return this.vatTuSelectedInKho[foundIndex].con_thieu;
      }
      return this.quantityData[index].so_luong_trong_kho_ban_dau;
    },

    calcRemainingNeed(index: number) {
      if (!this.selectingKhoItem) {
        return this.vatTuSelectedInKho[index].so_luong_can.toFixed(2);
      }

      const foundIndex = this.indexConvert(index);

      const result = this.vatTuSelectedInKho[foundIndex].so_luong_can -
        this.quantityData[index].so_luong_se_lay;

      if (result > 0) return result.toFixed(2);

      return 0;
    },

    thanhTienCalc(index: number, donGia: number) {
      if (!this.selectingKhoItem) {
        return formatCurrencyAndQuantity(this.supplies[index].pivot.so_luong * donGia);
      }

      return formatCurrencyAndQuantity(
        this.quantityData[index].so_luong_se_lay * donGia
      );
    },

    indexConvert(indexOfComputedArray: number) {
      // từ index của quantityData ==> index của vatTuSelectedInKho
      return this.vatTuSelectedInKho.findIndex(
        ({ id_vat_tu }) => id_vat_tu === this.quantityData[indexOfComputedArray].id_vat_tu
      );
    }
  },
});
