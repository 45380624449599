import { apiServiceInstance } from "@/core/helpers/utils";
import {
  ApiResponse,
  IDinhMucCoDinhNewResponseBase,
  IVatTuResponseBase,
  PaginationResponseBase
} from '../interfaces/ApiResponses';
import { ICreateDinhMucCoDinh, IPaginationParams } from '../interfaces/Models';
import { useDateTime } from '@/core/hooks/use-date-time-hook';

const { formatDatePayload } = useDateTime();

export interface IDinhMucCoDinhFilterParams extends IPaginationParams {
  id_cap_sc?: number;
  id_dm_toa_xe?: number;
}

export class DinhMucCoDinhService {
  static get(id: number): Promise<{
    data: ApiResponse<IDinhMucCoDinhNewResponseBase>
  }> {
    return apiServiceInstance().vueInstance.axios.get(`/api/v1/dinh-muc-co-dinh/chi-tiet/${id}`);
  }

  static getList({
    id_cap_sc,
    id_dm_toa_xe,
    current_page,
    per_page,
  }: IDinhMucCoDinhFilterParams): Promise<{
    data: ApiResponse<PaginationResponseBase<IDinhMucCoDinhNewResponseBase>>
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      '/api/v1/dinh-muc-co-dinh/danh-sach',
      {
        params: {
          page: current_page || 1,
          ...(id_cap_sc && {
            id_cap_sc,
          }),
          ...(id_dm_toa_xe && {
            id_dm_toa_xe,
          }),
        },
        headers: {
          perpage: per_page || 1000,
        }
      }
    );
  }

  static async getDinhMucCoDinhByIdCapSuaChuaAndDMTX({
    id_cap_sc,
    id_dm_toa_xe
  }): Promise<IDinhMucCoDinhNewResponseBase | null> {
    const { data: { data: { data } } } = await this.getList({ id_cap_sc, id_dm_toa_xe });

    if (data.length) return data[0];

    return null;
  }

  static async attachVatTuItems({
    id_dinh_muc,
    vat_tu_items,
  }: {
    id_dinh_muc: number;
    vat_tu_items: IVatTuResponseBase[]
  }) {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
      ] = "multipart/form-data";

    const bodyFormData = new FormData();

    vat_tu_items.forEach(({ id, so_luong }) => {
      bodyFormData.append(`dmVatTu[${id}][so_luong]`, so_luong.toString());
    });

    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/dinh-muc-co-dinh/${id_dinh_muc}/vat-tu`,
      bodyFormData
    );
  }

  static create({
    id_cap_sc,
    ngay_ban_hanh,
    ngay_het_han,
    mo_ta,
    id_danh_muc_toa_xe,
  }: ICreateDinhMucCoDinh): Promise<{
    data: ApiResponse<IDinhMucCoDinhNewResponseBase>
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
      ] = "multipart/form-data";

    const bodyFormData = new FormData();

    bodyFormData.append('dinhMucCoDinh[mo_ta]', mo_ta);
    bodyFormData.append('dinhMucCoDinh[id_cap_sc]', id_cap_sc.toString());
    bodyFormData.append('dinhMucCoDinh[id_danh_muc_toa_xe]', id_danh_muc_toa_xe.toString());
    bodyFormData.append('dinhMucCoDinh[ngay_ban_hanh]', formatDatePayload(ngay_ban_hanh));
    bodyFormData.append('dinhMucCoDinh[ngay_het_han]', formatDatePayload(ngay_het_han));
    bodyFormData.append('dinhMucCoDinh[mo_ta]', mo_ta);

    return apiServiceInstance().vueInstance.axios.post(
      '/api/v1/dinh-muc-co-dinh/tao-moi',
      bodyFormData,
    );
  }
}
