
import { defineComponent, PropType } from "vue";
import { formatCurrencyAndQuantity } from "@/core/helpers/utils";
import { useButtonCustom } from '../../../../../core/hooks/use-button-custom';

import ButtonCustom from '../../../../../components/shared/buttons/ButtonCustom.vue';

export default defineComponent({
  components: { ButtonCustom },

  name: 'supplies-from-warehouses',

  emits: ['on-click-clear-quantity-data', 'on-confirm-button-click'],

  setup() {
    const { ButtonsType, ButtonTypeColors } = useButtonCustom();

    return {
      ButtonsType,
      ButtonTypeColors,
    };
  },

  props: {
    isPhieuXuatLe: {
      type: Boolean,
      default: false,
    },
    isViewOnly: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array as PropType<
        Array<{
          ten_kho: string;
          tong_tien: number;
          tong_tien_bang_chu: string;
          vat_tu_da_lay: {
            ten_vat_tu: string;
            so_luong_lay: number;
            don_gia: number;
            con_lai: number;
            don_vi_tinh: string;
          }[];
        }>
      >,
    },
  },

  data() {
    return {
      formatCurrencyAndQuantity,
    };
  },

  methods: {
    clearQuantityDataClickedHandler() {
      this.$emit('on-click-clear-quantity-data');
    },
    confirmButtonClickedHandler() {
      this.$emit('on-confirm-button-click');
    },
  },
});
