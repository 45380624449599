
import { defineComponent, PropType, ref, watch } from 'vue';
import { IVatTuSelectedInKho } from '@/core/interfaces/Models';
import { formatCurrencyAndQuantity } from '@/core/helpers/utils';

import DivCol from '../../../../../layout/_shared/DivCol.vue';
import DivRow from '../../../../../layout/_shared/DivRow.vue';
import LabelCustom from '../../../../../components/shared/label-custom/LabelCustom.vue';

export default defineComponent({
  name: 'supply-getting-component',

  components: {
    DivCol,
    DivRow,
    LabelCustom,
  },

  emits: ['on-close-dialog'],

  data() {
    return {
      formatCurrencyAndQuantity,
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    supplyData: {
      type: Object as PropType<{
        ten_vat_tu: string;
        don_gia: number;
        don_vi_tinh: string;
      }>,
      required: true,
    },
    supplyGettingData: {
      type: Object as PropType<IVatTuSelectedInKho>,
      required: true,
    },
  },

  setup(props) {
    const isDisplay = ref(false);

    watch(
      () => props.isOpen,
      (currentValue) => {
        isDisplay.value = currentValue;
      },
      { deep: true }
    );

    return {
      isDisplay,
    };
  },

  computed: {
    titleComputed() {
      return `Thông tin xuất kho của vật tư ${this.supplyData.ten_vat_tu}`;
    },

    totalPriceOutput() {
      let totalPrice = 0;

      this.supplyGettingData.kho_arr.forEach((item) => {
        totalPrice += item.tong_so_trong_kho * this.supplyData.don_gia;
      });

      return this.formatCurrencyAndQuantity(totalPrice, false);
    },
  },

  methods: {
    handleClose() {
      this.$emit("on-close-dialog");
    },
  },
});
