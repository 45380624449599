import { IPhieuXuatKhoResponseBase, ReceiptBaseResponse } from '@/core/interfaces/ApiResponses';
import { useRouterCustom } from '@/core/hooks/use-router-custom';
import { useEnv } from '@/core/hooks/useEnv';

const usePhieuXuat = () => {
  const { push } = useRouterCustom();

  const { allowUpdateTaiKhoanPhieuXuatKhac } = useEnv();

  const goToEditPhieuXuatLink = async (phieuXuatData: IPhieuXuatKhoResponseBase | ReceiptBaseResponse) => {
    const { id, id_kho } = phieuXuatData;

    if (phieuXuatData.id_bien_ban_giai_the === 0) {
      await push(`/quan-ly/kho/${id_kho}/phieu-xuat-kho-khac/chinh-sua/${id}`);

      return;
    }

    await push(`/quan-ly/phieu-xuat/cap-nhat/${id}`);
  }

  const isPhieuXuatEditable = (phieuXuatData: IPhieuXuatKhoResponseBase | ReceiptBaseResponse) => {
    if (!phieuXuatData) return false;

    return !(phieuXuatData.is_approved || phieuXuatData.hoan_thanh || phieuXuatData.ngay_hoan_thanh);
  }

  const allowEditingTaiKhoan = (phieuXuatData: IPhieuXuatKhoResponseBase | ReceiptBaseResponse) => {
    if (!phieuXuatData) return true;

    return phieuXuatData.id_bien_ban_giai_the === 0 && allowUpdateTaiKhoanPhieuXuatKhac.value;
  }

  return {
    allowEditingTaiKhoan,
    goToEditPhieuXuatLink,
    isPhieuXuatEditable,
  };
}

export default usePhieuXuat;
